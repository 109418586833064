/*******************************************************************************
 * Styles for the base elements of the theme.
 ******************************************************************************/

// Typography

p,
h1,
h2,
h3,
h4,
h5,
h6,
em,
div,
span,
strong {
  color: var(--global-text-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a,
table.table a {
  color: var(--global-theme-color);
  &:hover {
    color: var(--global-theme-color);
    text-decoration: underline;
  }
  &:hover:after :not(.nav-item.dropdown) {
    width: 100%;
  }
}

blockquote {
  background: var(--global-bg-color);
  border-left: 2px solid var(--global-theme-color);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  font-size: 1.2rem;
}

// Math

.equation {
  margin-bottom: 1rem;
  text-align: center;
}

// Caption

.caption {
  font-size: 0.875rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

// Citation
.citation,
.citation-number {
  color: var(--global-theme-color);
}

// Profile

.profile {
  margin-left: 1rem;
  width: 100%;

  .address {
    margin-bottom: 5px;
    margin-top: 5px;
    font-family: monospace;
    p {
      display: inline-block;
      margin: 0;
    }
  }
}

@media (min-width: 576px) {
  .profile {
    width: 30%;
    .address {
      p {
        display: block;
      }
    }
  }
}

.post-description {
  margin-bottom: 2rem;
  font-size: 0.875rem;
  a {
    color: inherit;
    &:hover {
      color: var(--global-theme-color);
      text-decoration: none;
    }
  }
}

.content {
  position: relative;
  overflow: hidden;
  line-height: 40px;

  &__container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;

    &__text {
      display: inline;
      float: left;
      margin: 0;
    }

    &__list {
      margin-top: 0;
      padding-left: 38px;
      text-align: left;
      list-style: none;

      -webkit-animation-name: change;
      -webkit-animation-duration: 30s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 30s;
      animation-iteration-count: infinite;

      &__item {
        line-height: 40px;
        margin: 0;
      }
    }
  }
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}
// Navbar customization

.navbar {
  box-shadow: none;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(to right, red, purple);
  background-color: var(--global-bg-color);
  border-image-slice: 0 0 1 0;
  opacity: 0.95;
}
.navbar .dropdown-menu {
  background-color: var(--global-bg-color);
  a:not(.active) {
    color: var(--global-text-color);
  }
  a:hover {
    color: var(--global-hover-color);
  }
}
.dropdown-item {
  color: var(--global-text-color);
  &:hover {
    color: var(--global-hover-color);
    background-color: var(--global-bg-color);
  }
}
.navbar.navbar-light {
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .navbar-brand {
    color: var(--global-text-color);
  }
  .navbar-nav .nav-item .nav-link {
    color: var(--global-text-color);
    &:hover {
      color: var(--global-hover-color);
    }
  }
  .navbar-nav .nav-item.active > .nav-link {
    background-color: inherit;
    font-weight: bolder;
    color: var(--global-theme-color);
    &:hover {
      color: var(--global-hover-color);
    }
  }
  .navbar-brand.social {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 1.7rem;
    a {
      i::before {
        color: var(--global-text-color);
        -webkit-transition: all 0.2s ease-in-out;
      }
      &:hover {
        i::before {
          color: var(--global-theme-color);
        }
      }
    }
  }
}

.navbar-toggler {
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    background-color: var(--global-text-color);
    border-radius: 1px;
    margin-bottom: 4px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
}

.navbar-toggler.collapsed {
  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}

// Navbar pulse
@-webkit-keyframes navbar-pulse {
  0% {
    border-image: 0% 50%;
  }
  50% {
    border-image: 100% 50%;
  }
  100% {
    border-image: 0% 50%;
  }
}
@-moz-keyframes navbar-pulse {
  0% {
    border-image: 0% 50%;
  }
  50% {
    border-image: 100% 50%;
  }
  100% {
    border-image: 0% 50%;
  }
}
@keyframes navbar-pulse {
  0% {
    border-image: 0% 50%;
  }
  50% {
    border-image: 100% 50%;
  }
  100% {
    border-image: 0% 50%;
  }
}

// News
.news {
  padding-top: 5%;
}

.news table td {
  font-size: 1rem;
  color: var(--global-text-color);
}

.news table th {
  color: var(--global-text-color);
}

// Social (bottom)

.social {
  text-align: center;
  padding-top: 5%;
  .contact-icons {
    font-size: 4rem;
    a {
      i::before {
        color: var(--global-text-color);
        -webkit-transition: all 0.2s ease-in-out;
      }
      &:hover {
        i::before {
          color: var(--global-theme-color);
        }
      }
    }
  }
  .contact-note {
    font-size: 0.8rem;
  }
}

// Footer
footer.fixed-bottom {
  background-color: var(--global-footer-bg-color);
  font-size: 0.75rem;
  .container {
    color: var(--global-footer-text-color);
    padding-top: 9px;
    padding-bottom: 8px;
  }
  a {
    color: var(--global-footer-link-color);
    &:hover {
      color: var(--global-theme-color);
      text-decoration: none;
    }
  }
}

footer.sticky-bottom {
  // border-top: 1px solid $grey-color-light;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 0.9rem;
}

// Blog

.header-bar {
  border-bottom: 1px solid $grey-color-light;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 5rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 5rem;
  }
}

.post-list {
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  li {
    border-bottom: 1px solid $grey-color-light;
    list-style: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .post-meta {
      color: var(--global-text-color-light);
      font-size: 0.875rem;
      margin-bottom: 0;
    }
    a {
      color: var(--global-text-color);
      text-decoration: none;
      &:hover {
        color: var(--global-theme-color);
      }
    }
  }
}

.pagination {
  .page-item {
    .page-link {
      color: var(--global-text-color);
      &:hover {
        color: $black-color;
      }
    }
    &.active .page-link {
      color: $white-color;
      background-color: var(--global-theme-color);
      &:hover {
        background-color: var(--global-theme-color);
      }
    }
  }
}

// Distill

.distill {
  a:hover {
    border-bottom-color: var(--global-theme-color);
    text-decoration: none;
  }
}

// Projects

.projects {
  a {
    text-decoration: none;

    &:hover {
      .card-title {
        color: var(--global-theme-color);
      }
    }
  }

  .card {
    img {
      width: 100%;
    }
    .card-text {
      color: #fafafab7;
    }
    .card-title {
      color: var(--global-text-color);
    }
    background: var(--global-code-bg-color);
  }

  .card-item {
    width: auto;
    margin-bottom: 10px;

    .row {
      display: flex;
      align-items: center;
    }
  }

  .grid-item {
    width: 250px;
    margin-bottom: 10px;
  }

  h2.category {
    color: $grey-color-light;
    border-bottom: 1px solid $grey-color-light;
    padding-top: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: right;
  }
}

// Publications

.publications {
  margin-top: 2rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 2rem;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h2 {
    margin-bottom: 1rem;
    span {
      font-size: 1.5rem;
    }
  }
  h2.year {
    color: $grey-color-light;
    border-top: 1px solid $grey-color-light;
    padding-top: 1rem;
    margin-top: 2rem;
    margin-bottom: -2rem;
    text-align: right;
  }
  ol.bibliography {
    list-style: none;
    padding: 0;
    margin-top: 0;

    li {
      margin-bottom: 1rem;
      .abbr {
        height: 2rem;
        margin-bottom: 0.5rem;
        abbr {
          display: inline-block;
          background-color: var(--global-theme-color);
          padding-left: 1rem;
          padding-right: 1rem;
          a {
            color: white;
            &:hover {
              text-decoration: none;
            }
          }
        }
        .award {
          color: var(--global-theme-color) !important;
          border: 1px solid var(--global-theme-color);
        }
      }
      .title {
        font-weight: bolder;
      }
      .author {
        a {
          border-bottom: 1px dashed var(--global-theme-color);
          &:hover {
            border-bottom-style: solid;
            text-decoration: none;
          }
        }
        > em {
          border-bottom: 1px solid;
          font-style: normal;
        }
      }
      .links {
        a.btn {
          color: var(--global-text-color);
          border: 1px solid var(--global-text-color);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          &:hover {
            color: var(--global-theme-color);
            border-color: var(--global-theme-color);
          }
        }
      }
      .hidden {
        font-size: 0.875rem;
        max-height: 0px;
        overflow: hidden;
        text-align: justify;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;

        p {
          line-height: 1.4em;
          margin: 10px;
        }
        pre {
          font-size: 1em;
          line-height: 1.4em;
          padding: 10px;
        }
      }
      .hidden.open {
        max-height: 100em;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;
      }
      div.abstract.hidden {
        border: dashed 1px var(--global-bg-color);
      }
      div.abstract.hidden.open {
        border-color: var(--global-text-color);
      }
    }
  }
}

// Rouge Color Customization
figure.highlight {
  margin: 0 0 1rem;
}

pre {
  color: var(--global-theme-color);
  background-color: var(--global-code-bg-color);
  border-radius: 6px;
  padding: 6px 12px;
  pre,
  code {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }
}

code {
  color: var(--global-theme-color);
  background-color: var(--global-code-bg-color);
  border-radius: 3px;
  padding: 3px 3px;
}

// Transitioning Themes
html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

// Anagrams
$char-w: 1ch;
$gap: 0.5 * $char-w;
$n-char: 7;
$in-w: $n-char * ($char-w + $gap);

.anagram-input {
  display: block;
  margin: 2em auto;
  border: none;
  padding: 0;
  width: $in-w;
  background: repeating-linear-gradient(
      90deg,
      var(--global-theme-color) 0,
      var(--global-theme-color) $char-w,
      transparent 0,
      transparent $char-w + $gap
    )
    0 100% / #{$in-w - $gap} 2px no-repeat;
  color: var(--global-text-color);
  font: 5ch menlo, droid sans mono, consolas, monospace;
  letter-spacing: $gap;

  &:focus {
    outline: none;
    color: var(--global-theme-color);
  }
}

.anagrams-table table tr {
  font-size: 1rem;
  color: var(--global-text-color);
  &:hover {
    color: var(--global-theme-color);
  }
}

.anagrams-table table th {
  color: var(--global-text-color);
}

.st-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--global-bg-color);
  color: var(--global-text-color);
  padding: 0.25rem 0.75rem;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--global-code-bg-color);
  border-image: initial;
  &:hover {
    color: var(--global-hover-color);
    border-color: var(--global-hover-color);
  }
}
// From streamlit
.anagrams-btn {
  -webkit-appearance: none;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-color: rgb(19, 23, 32);
  border-bottom-color: rgba(250, 250, 250, 0.2);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0px;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(250, 250, 250, 0.2);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgba(250, 250, 250, 0.2);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgba(250, 250, 250, 0.2);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: rgb(250, 250, 250);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    -webkit-appearance: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    align-items: center;
    background-color: rgb(19, 23, 32);
    border-bottom-color: rgb(246, 51, 102);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0px;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(246, 51, 102);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(246, 51, 102);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(246, 51, 102);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-style: solid;
    border-top-width: 1px;
    box-sizing: border-box;
    color: rgb(246, 51, 102);
  }
}

.alert-danger {
  background: rgba(255, 75, 75, 0.3);
  border: 1px solid rgba(255, 75, 75, 1);
  color: rgb(255, 75, 75);
}

// Email Form
#fs-frm input,
#fs-frm select,
#fs-frm textarea,
#fs-frm fieldset,
#fs-frm optgroup,
#fs-frm label,
#fs-frm #card-element:disabled {
  font-family: inherit;
  font-size: 100%;
  color: inherit;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#fs-frm label,
#fs-frm legend,
#fs-frm ::placeholder {
  margin-bottom: 0.5rem;
  padding-top: 0.2rem;
  display: flex;
  align-items: baseline;
}

/* border, padding, margin, width */
#fs-frm input,
#fs-frm select,
#fs-frm textarea,
#fs-frm #card-element {
  border: 1px solid var(--global-code-bg-color);
  border-radius: 4px;
  background-color: var(--global-bg-color);
  padding: 0.75em 1rem;
  margin-bottom: 1.5rem;
}

#fs-frm input:focus,
#fs-frm select:focus,
#fs-frm textarea:focus {
  box-shadow: 0 0 0 1px var(--global-hover-color);
  outline: none;
}
#fs-frm input:hover,
#fs-frm select:hover,
#fs-frm textarea:hover {
  box-shadow: 0 0 0 1px var(--global-hover-color);
}

#fs-frm [type="text"],
#fs-frm [type="email"] {
  width: 100%;
}

#fs-frm [type="button"],
#fs-frm [type="submit"],
#fs-frm [type="reset"] {
  width: auto;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

#fs-frm [type="button"]:focus,
#fs-frm [type="submit"]:focus,
#fs-frm [type="reset"]:focus {
  outline: none;
}

#fs-frm [type="submit"],
#fs-frm [type="reset"] {
  margin-bottom: 0;
}

#fs-frm select {
  text-transform: none;
}

#fs-frm [type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
  display: inline-block;
  width: auto;
  margin: 0 0.5em 0 0 !important;
}

#fs-frm [type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

/* address, locale */
#fs-frm fieldset.locale input[name="city"],
#fs-frm fieldset.locale select[name="state"],
#fs-frm fieldset.locale input[name="postal-code"] {
  display: inline;
}

#fs-frm fieldset.locale input[name="city"] {
  width: 52%;
}

#fs-frm fieldset.locale select[name="state"],
#fs-frm fieldset.locale input[name="postal-code"] {
  width: 20%;
}

#fs-frm fieldset.locale input[name="city"],
#fs-frm fieldset.locale select[name="state"] {
  margin-right: 3%;
}
